.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight-row {
  background: #e8d73968 !important;
}
.highlight-row:hover {
  background: #e8d73968 !important;
  opacity: 1 !important;
}

.ant-table-row .disqulifide {
  background-color: rgba(250, 99, 101, 0.3) !important;
}

.ant-table-placeholder {
  z-index: 0 !important;
}
.disqulifide {
  background-color: rgba(250, 99, 101, 0.3) !important;
  background: #fa63654d !important;
}

.mud-pump-radio span:last-child {
  font-size: 15px !important;
}

.calender-export::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input.calender-export::-webkit-datetime-edit-day-field,
input.calender-export::-webkit-datetime-edit-month-field,
input.calender-export::-webkit-datetime-edit-year-field,
input.calender-export::-webkit-datetime-edit-hour-field,
input.calender-export::-webkit-datetime-edit-minute-field,
input.calender-export::-webkit-datetime-edit-second-field {
  color: white !important;
}

input::-webkit-datetime-edit-day-field,
input::-webkit-datetime-edit-month-field,
input::-webkit-datetime-edit-year-field,
input::-webkit-datetime-edit-hour-field,
input::-webkit-datetime-edit-minute-field,
input::-webkit-datetime-edit-second-field {
  color: black !important;
}

.sort-grid-ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 4 columns as an example */
  gap: 1px;
  list-style: none;
  padding: 5px;
}

.grid-item {
  transition: transform 250ms;
}

.Toastify__toast-container div {
  overflow: unset !important;
  border-radius: 7px !important;
}

/* Style for grouped rows */
.grouped-row {
  background-color: #f0f0f0; /* Example background color for grouped rows */
}

/* Disable hover effect for grouped rows */
.grouped-row:hover {
  background-color: #f0f0f0 !important; /* Prevent background color change on hover */
}

/* Regular table row hover effect */
.ant-table-tbody > tr:hover {
  background-color: #e6f7ff; /* Default hover color for non-grouped rows */
}

.stands-table td {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.root-stands-table div.ant-table.ant-table-small {
  min-height: 238px !important;
}

.ant-drawer-menu .ant-drawer .ant-drawer-content-wrapper {
  width: 200px !important;
}

.sidebar-menu-container {
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
}

.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.logo-container img {
  transition: width 0.3s;
}

.menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #9e9e9e;
}

.icon {
  min-width: 40px;
}

.menu-label {
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.export-tabs .ant-tabs-content-holder {
  overflow-y: auto;
}

.custom-search-icon {
  font-size: 14px !important; /* Adjust size as needed */
  width: 14px !important;
  height: 14px !important;
}
